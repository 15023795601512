import { building } from './resultLocationDataStruct';
import { CompletenessType, ConverterType, Unit } from './DataTypes';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BlockParsingData, BuildingBlockType, BuildingPart, ConverterBuilding } from "./BuildingPart";
import { ErrorLog, ErrorLogCell2, ErrorType } from "./ErrorLog";
import * as THREE from '@teneleven/three';
import { BuildingHouseUnit, WindowType } from './BuildingHouseUnit';
import { Polygon } from './DataTypes';
import { getCircle } from './MeshMaker';
import { checkPolygonError, CheckPolygonOverlap, CheckPolygonsApart, getPolygonType, JSTSGeoToTHREEGeo } from './CoreAndHouseController';
import { switchLineDashedState } from './FileParser';
import { BuildingGroup } from './BuildingGroup';
import userSettingData from './SettingModal';
import { ErrorList, TypeError } from './Error';
import { stringify } from 'querystring';

type buildingsParam = {
  buildings: ConverterBuilding[],
  blockBs: BuildingPart[],
  cores: BuildingCoreUnit[],
  houses: BuildingHouseUnit[],
  wrongPolygons?: Polygon[],
}
let errorLogs: ErrorLogCell2[] = [];



export type WindowInfoType= {
  windowPoly: WindowType, 
  p1: THREE.Vector3, 
  p2: THREE.Vector3, 
  line: THREE.Line3, 
  matrixWorld:THREE.Matrix4,
  errorLog: ErrorLogCell2[],
  errorPolygonGroup: THREE.Group,
}

export function checkTypeError(loadFileErrorLogs: ErrorLogCell2[], buildings: buildingsParam, dataUnit: Unit, errorList: ErrorList) {

  buildings.houses && buildings.houses.forEach(house => house.RebuildOutputPolygon());
//  checkBlockName(buildings, loadFileErrorLogs);
  // 폴리곤 오류 체크
  let res = checkPolygonError([...buildings.buildings, ...buildings.blockBs, ...buildings.cores, ...buildings.houses,], dataUnit);
  checkPolygonOverlap(res.overlap, errorList);
  checkApartError(res.apart, errorList);
  checkDisconnectedError(res.disConnected, errorList, buildings.cores, buildings.houses);
}

function checkPolygonOverlap(overlap: any, errorList: ErrorList) {

  //* 교차 한번에
  overlap.forEach((res: any, i: number) => {
    const [first, second] = res;

    //* 그룹1
    const group = new THREE.Group();
    if (first.type === "POLYGON") {
      let mesh = JSTSGeoToTHREEGeo(first.jsts);
      mesh.visible = false;
      group.add(mesh);
   //   groupAll.children.push(group);
      mesh.material.side = THREE.DoubleSide;
      first.info.building.renderGroup.parent.children.push(group);
    }


    let msg = "";
    //@ts-ignore
    msg = `${first.info.building.name}과 ${second.info.building.name}이 중복되어 있습니다.`;
    // 폴리곤 - 폴리곤의 중복
    if (first.type === "POLYGON" && second.type === "POLYGON") {
      //@ts-ignore
      let overlapPercent = first.overlapPercent ? first.overlapPercent : 100;

      // INFO
      if (overlapPercent <= 0.1 && userSettingData.myTypeSettingData.layerOverlap.enable) {
        errorList.addError(new TypeError({
          title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
          msg: `교차 된 ${first.info.building.name} 과 ${first.info.building.name} 이 자동 보정 되었습니다. `,
          type: ErrorType.Info,
          id: [first.info.building.uuid, second.info.building.uuid],
          //@ts-ignore
          hilightPolygon: group,
        }));
      }
      // WARNING
      else if (overlapPercent > 0.1 && overlapPercent <= 1 && userSettingData.myTypeSettingData.layerOverlap.enable) {
        errorList.addError(new TypeError({
          title: "[형태적 오류] 유효하지 않은 데이터가 존재합니다.",
          msg: `${first.info.building.name}과 ${second.info.building.name}의 면적이 서로 교차되어 있습니다.`,
          type: ErrorType.Warning,
          id: [first.info.building.uuid, second.info.building.uuid],
          //@ts-ignore
          hilightPolygon: group,
        }));
        if (first.info.building.completeness !== CompletenessType.error) first.info.building.completeness = CompletenessType.warning;
      }
      else {
        first.info.building.completeness = CompletenessType.error;
        second.info.building.completeness = CompletenessType.error;
        errorList.addError(new TypeError({
          title: "[형태적 오류] 유효하지 않은 데이터가 존재합니다.",
          msg: `${first.info.building.name}과 ${second.info.building.name}의 면적이 서로 교차되어 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [first.info.building.uuid, second.info.building.uuid],
          //@ts-ignore
          hilightPolygon: group,
        }));
        first.info.building.complete = CompletenessType.error;
        second.info.building.complete = CompletenessType.error;
      }
    }
  })
}

function checkApartError(apart: any, errorList: ErrorList) {
  //* 이격
  apart.forEach((res:any) => {
  if (userSettingData.myTypeSettingData.polygonOffset.enable && res.distance < 1) { // info
    res.core.building.ErrorPolygonGroup.add(res.line);
    errorList.addError(new TypeError({
      title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
      msg:  `${res.core.building.name} 과 ${res.house.building.name}의 이격된 라인이 자동 보정 되었습니다.`,
      type: ErrorType.Info,
      id: [res.core.building.uuid, res.house.building.uuid],
      //@ts-ignore
      hilightPolygon: res.line,
    }));

    // loadFileErrorLogs.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
    //   `${res.core.building.name} 과 ${res.house.building.name}의 이격된 라인이 자동 보정 되었습니다`, res.line, undefined,
    // ));
  }
  else if (res.distance >= 1) { // warning
    res.core.building.ErrorPolygonGroup.add(res.line);
    errorList.addError(new TypeError({
      title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
      msg: `${res.core.building.name} 과 ${res.house.building.name}간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
      type: ErrorType.Warning,
      id: [res.core.building.uuid, res.house.building.uuid],
      //@ts-ignore
      hilightPolygon: res.line,
    }));
    // loadFileErrorLogs.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다. `,
    //   `${res.core.building.name}과 ${res.house.building.name}간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`, res.line));
    //   if (res.core.building.completeness !== CompletenessType.error) res.core.info.building.completeness = CompletenessType.warning;
    //   if (res.house.building.completeness !== CompletenessType.error) res.house.info.building.completeness = CompletenessType.warning;
  }
  // 삭제 2021-10-29
  // else { // error
  //   // res.core.building.completeness = CompletenessType.error;
  //   // res.house.building.completeness = CompletenessType.error;    
  //   // res.core.building.ErrorPolygonGroup.add(res.line);
  //   // errorList.addError(new TypeError({
  //   //   title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
  //   //   msg: `${res.core.building.name} 과 ${res.house.building.name} 간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`, 
  //   //   type: ErrorType.Error,
  //   //   id: [res.core.building.uuid, res.house.building.uuid],
  //   //   //@ts-ignore
  //   //   hilightPolygon: res.line,
  //   // }));
  //   // res.core.building.complete = CompletenessType.error;
  //   // res.house.building.complete = CompletenessType.error;
  // }
})
}

function checkDisconnectedError(disConnected: any, errorList: ErrorList, cores: BuildingCoreUnit[], houses: BuildingHouseUnit[]) {

  //* 끊김
  disConnected.forEach((res: any) => {
    if (res.confirm && userSettingData.myTypeSettingData.unClosedPolygon.enable) {
      let group = new THREE.Group();
      let v1 = new THREE.Vector3(res.jsts.p0.x, res.jsts.p0.y, 0);
      let v2 = new THREE.Vector3(res.jsts.p1.x, res.jsts.p1.y, 0);

      let circleMesh1 = getCircle(v1, v1, new THREE.Color(1, 0, 0), 5, 1);
      let circleMesh2 = getCircle(v2, v2, new THREE.Color(1, 0, 0), 5, 1);
      circleMesh1.visible = true;
      circleMesh2.visible = true;

      group.add(circleMesh1);
      group.add(circleMesh2);
      res.building.building.ErrorPolygonGroup.add(group);
      let conLayerName: string[] = [];
      res.building.building.polygon.map((poly: Polygon) => {
        if (poly.layer === "CON" || poly.layer === "con") {
          conLayerName.push(poly.layer);
        }
      })

      errorList.addError(new TypeError({
        title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
        msg: `${conLayerName[0]} 의 Poly line이 닫힌 Poly line으로 자동보정 되었습니다.`,
        components: [res.building.building],
        type: ErrorType.Info,
        id: [res.building.building.uuid],
      }))
      // loadFileErrorLogs.push(makeInfoInformation(
      //   `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
      //   `${conLayerName[0]}의 Poly line이 닫힌 Poly line으로 자동보정 되었습니다.`,
      //   new THREE.Group(), undefined, {
      //   components: [res.building.building],
      // }
      // ));
    }



  })
  const components: Array<BuildingHouseUnit | BuildingCoreUnit> = [...cores, ...houses];
  components.forEach((component: BuildingCoreUnit | BuildingHouseUnit) => {
    let group = new THREE.Group();
    const polyArr: Polygon[] = [];
    component.polygon.forEach((poly: Polygon) => {
      if (!poly.shape && !poly.layer.toUpperCase().startsWith("WIN")) {
        let v1 = new THREE.Vector3(poly.vertices[0].x, poly.vertices[0].y, 0);
        let v2 = new THREE.Vector3(poly.vertices[poly.vertices.length - 1].x, poly.vertices[poly.vertices.length - 1].y, 0);
        let circleMesh1 = getCircle(v1, v1, new THREE.Color(1, 0, 0), 5, 1000);
        let circleMesh2 = getCircle(v2, v2, new THREE.Color(1, 0, 0), 5, 1000);
        circleMesh1.visible = false;
        circleMesh2.visible = false;
        group.add(circleMesh1);
        group.add(circleMesh2);
        polyArr.push(poly);
      }
    })
    if (group.children.length > 0) {
      component.renderGroup.add(group);
      errorList.addError(new TypeError({
        title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        msg: `${component.name}에 닫혀 있지 않은 Poly line 이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: [component.uuid],
        //@ts-ignore
        polygons: polyArr,
        //@ts-ignore
        hilightPolygon: group,
      }))
    }
  })
}

export function checkConnectionComponent(buildings: buildingsParam, errorList: ErrorList) {

  let houses: BuildingHouseUnit[] = [];
  let cores: BuildingCoreUnit[] = [];
  let isConnected = true;

  buildings.blockBs.forEach(blockB => {
    //@ts-ignore
    houses = blockB.parts.filter(part => part.componentType === "house");
    //@ts-ignore
    cores = blockB.parts.filter(part => part.componentType === "core");
  });

  if (cores.length < 1 && houses.length >= 1) {
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg: `${buildings.houses.map(house => house.name).join('/')}에 연결 코어(C)가 없습니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: buildings.houses.map(h => h.uuid),
    }));
    isConnected = false;
  }
  if (cores.length >= 1 && houses.length < 1) {
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg: `${cores.map(core => core.name).join('/')}에 연결 유닛(U)이 없습니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: cores.map(core => core.uuid),
    }));
    isConnected = false;
  }
  return isConnected;
}



// 레이어 이름 에러 체크
export function checkLayerNameError(parsingData: Array<ConverterBuilding | BuildingPart>, errorList: ErrorList) {
  // 캐드컨버터가 요구하는 layer Naming을 지키지 않은 경우  

  parsingData.forEach(block => {
    let layerHash = new Map();
    let correctName = ["CON", "WIN1", "WIN2"];  
    
    //@ts-ignore
    if (block.buildingType && block.buildingType === "component" && block.componentType === "core") {      
      correctName = ["CON"];
    }
  
    //@ts-ignore
    block.polygon.forEach((poly: Polygon) => {      
      if (poly.layer && correctName.indexOf(poly.layer.toUpperCase()) === -1) {
        poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
        if (!layerHash.get(poly.layer)) layerHash.set(poly.layer, [poly]);
        else {
          let n = layerHash.get(poly.layer);
          n.push(poly);
          layerHash.set(poly.layer, n);
        }
      }
    })

    layerHash.forEach((polygons, layerName) => {      
      errorList.addError(new TypeError({
        title: '[레이어 구조오류] 잘못된 레이어 구조로 저장 할 수 없습니다.',
        msg: `${layerName}은(는) 유효하지 않은 레이어 이름입니다. ${correctName.join('/')} 만 유효합니다. 수정이 필요한 레이어: ${layerName} 
        캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: [],
        polygons: polygons,
      }));

      changeLineColorRed(polygons, layerName);
    })    
  })

}


export function changeLineColorRed(polygons: Polygon[], layerName: string) {

  layerName = layerName.toUpperCase();

  if (layerName === "CON") {
    polygons.forEach(poly => {
      if (/^CON$/i.test(poly.layer)) {
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.color = new THREE.Color(1, 0, 0);
      }
    })
  }
  else if (layerName === "WIN1" || layerName === "WIN2") {
    polygons.forEach(poly => {
      if (/^WIN[1-2]$/i.test(poly.layer)) {
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.color = new THREE.Color(1, 0, 0);
      }
    })
  }
  else {
    polygons.forEach(poly => {
      if (poly.layer === layerName) {
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.color = new THREE.Color(1, 0, 0);
      }
    })
  }
}



/* F블록/B블록 안에 WIN1/WIN2 레이어의 라인/폴리라인/폴리곤이 있을 경우 */
export function checkLayerInBuilding(polygons: Polygon[], block: any, errorList: ErrorList) {

  
  let win1: Polygon[] = [];
  let win2: Polygon[] = [];
  let concrete: Polygon[] = [];
  let hilightPolygons: Polygon[] = [];

  polygons.forEach(poly => {
    let layerName = poly.layer.toUpperCase();
    if (layerName === "WIN1") win1.push(poly);
    else if (layerName === "WIN2") win2.push(poly);
    else if (layerName === "CON") concrete.push(poly);
    switchLineDashedState(poly.lineMesh.material, true);
  })

  if (win1.length || win2.length || concrete.length) {
    let layerName = [];
    let layerType = [];
    let layerTypeSet = new Set();

    concrete.length > 0 && layerName.push("CON");
    win1.length > 0 && layerName.push('WIN1');
    win2.length > 0 && layerName.push('WIN2');

    hilightPolygons = win1.concat(win2).concat(concrete);
    hilightPolygons.map(r => layerTypeSet.add(getPolygonType(r.type, r.shape)));
    layerType = Array.from(layerTypeSet);
    
    errorList.addError({
      type: ErrorType.Warning,
      title: '[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
      msg: `${block.name} 안에 ${layerName.join('/')}로 설정된 ${layerType.join('/')} 이 있습니다. `,
      id: [block.uuid],
      polygons: hilightPolygons,
    })
  }
}

/* 블록 이름 종류 count*/
export function countBlockName(blocks: ConverterBuilding[] | BuildingPart[] | BuildingHouseUnit[] | BuildingCoreUnit[]) {
  let blockNames = new Set();
  
  blocks.forEach((block: ConverterBuilding | BuildingPart | BuildingHouseUnit | BuildingCoreUnit) => {
    blockNames.add(block.name);
    //@ts-ignore
    if (block.parts) {      
      addBlockNames(block.parts);
    }   
  })

  function addBlockNames(parts: ConverterBuilding[] | BuildingPart[] | BuildingHouseUnit[] | BuildingCoreUnit[]) { 
    parts.forEach((part: ConverterBuilding | BuildingPart | BuildingHouseUnit | BuildingCoreUnit) => {      
      blockNames.add(part.name);
      part.parts && addBlockNames(part.parts);

    });
    //@ts-ignore
  }

  return blockNames.size;
};

export function checkBlockStruct(buildings: ConverterBuilding[], errorList: ErrorList, wrongPolygons: Polygon[], type: ConverterType) {
  // F블록 레벨에서 F블록이 아닌 블록
  const notBlockF = buildings.filter(b => !/^[F][\d]+$/i.test(b.name) && !/^[F]$/i.test(b.name));
  const notBlockFCount = countBlockName(notBlockF);

  // F블록으로 묶이지 않은 동평면 블록 존재
  if (notBlockF.length > 0) {
    const errorPolygons: Polygon[] = [];
    notBlockF.map(block => {
      block.polygon.forEach(poly => {
        errorPolygons.push(poly);
        switchLineDashedState(poly.lineMesh.material, true);
      })
    })
    errorList.addError(new TypeError({
      title: '[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
      msg: `F블록 으로 설정되지 않은 ${notBlockFCount}개의 블록이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: notBlockF.map(block => block.uuid),
      //@ts-ignore
      components: notBlockF,
    }));
    return false;
  }

  let blockFError = false; // 블록 F 존재여부
  let blockBError = false;

  if (buildings.length === 0) {
    blockFError = true;
    blockBError = true;
  }


  // TODO F블록으로 지정되지 않은 N개 폴리라인/폴리곤이 있을 경우 (모든 레이어)
  let polygonTypes = new Set();
  let polygons: Polygon[] = [];

  if (type === ConverterType.myType) {
    wrongPolygons.forEach(poly => {
      polygonTypes.add(getPolygonType(poly.type, poly.shape));
      switchLineDashedState(poly.lineMesh.material, true);
      polygons.push(poly);
      buildings.length && buildings[0].renderGroup.add(poly.lineMesh);
    })

    if (wrongPolygons.length > 0) {
      errorList.addError(new TypeError({
        title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
        msg: `블록으로 지정되지 않은 ${Array.from(polygonTypes).join('/')}이 존재하여 자동 삭제 되었습니다.`,
        type: ErrorType.Info,
        id: [],
        //@ts-ignore
        polygons,
      }));
    }
  }



  let blockFName = "";

  const filteredBlockF = buildings.filter(block => /^[F]\d+$/i.test(block.name));
  if (filteredBlockF.length === 0)
    blockFError = true;
  else blockFName = filteredBlockF[0].name;

  if (blockFError) {
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg: `동 평면 블록 (F1)이 없습니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: [],
    }));
    return false;
  }

  const cores: Array<BuildingCoreUnit> = [];
  const houses: Array<BuildingHouseUnit> = [];

  buildings.forEach(building => { // blockF
    const notBlockB = building.parts.filter(b => !/^[B][\d]+$/i.test(b.name) && !/^[B]$/i.test(b.name));
    if (notBlockB.length > 0) {
      const errorPolygons: Polygon[] = [];
      notBlockB.map(block => {
        block.polygon.forEach((poly: Polygon) => {
          errorPolygons.push(poly);
          switchLineDashedState(poly.lineMesh.material, true);
        });
      })

      errorList.addError(new TypeError({
        title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        msg: `B블록 으로 설정되지 않은 ${notBlockB.length}개의 블록이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: notBlockB.map(block => block.uuid),
        //@ts-ignore
        polygons: errorPolygons,
      }));
      return false;
    }

    let notCoreAndHouse = 0;
    const notCoreAndHouseBlock: any = [];

    building.parts.forEach((part: BuildingPart) => { // blockB  
      let core = 0, house = 0;
      if (!part || !/^[B][\d]+$/i.test(part.name)) {
        return;
      }
      part.parts.forEach(component => {
        //@ts-ignore
        if (component.componentType === "core" && component.name.toUpperCase().startsWith("C")) {
          core++;
          cores.push(component as BuildingCoreUnit);
        }
        //@ts-ignore
        else if (component.componentType === "house" && component.name.toUpperCase().startsWith("U")) {
          house++;
          houses.push(component as BuildingHouseUnit);
        }
        else {
          notCoreAndHouse++;
          notCoreAndHouseBlock.push(component);
        }
      })


      const noExistBlock = [];
      if (core === 0) noExistBlock.push('코어 블록 (C)');
      if (house === 0) noExistBlock.push('유닛 블록 (U)');
      if (noExistBlock.length) {
        errorList.addError(new TypeError({
          title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg: `${part.name} 안에 ${noExistBlock.join('/')}이 없습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [part.uuid],
        }));
        result = false;
      }

      if (part.buildingType !== "group") {
        blockBError = true;
      }

      // B블록 안에 WIN1/WIN2/CON레이어가 있는경우
      checkLayerInBuilding(part.polygon, part, errorList);
    })

    //U/C블록 에 U/C블록으로 설정되지 않은 블록이 있을 경우
    if (notCoreAndHouse) {
      errorList.addError(new TypeError({
        title: '[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
        msg: `U/C블록 으로 설정되지 않은 ${notCoreAndHouse}개의 블록이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: notCoreAndHouseBlock.map((part: any) => part.uuid),
        components: notCoreAndHouseBlock,
      }));
      result = false;
    }

    // F블록 안에 WIN1/WIN2/CON레이어가 있는경우
    checkLayerInBuilding(building.polygon, building, errorList);

    // F안에 B블록 으로 지정되지 않은 N개의 (폴리라인/폴리곤) 이 있을 경우 (WIN레이어 제외)
    if (building.polygon.length > 0) {
      const polygonType = new Set();
      const polygons: Polygon[] = [];
      building.polygon.forEach((poly: Polygon) => {
        if (!/^WIN[1-2]$/i.test(poly.layer)) {
          polygonType.add(getPolygonType(poly.type, poly.shape));
          polygons.push(poly);
        }
      })
      errorList.addError(new TypeError({
        title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
        msg: `B블록으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`,
        type: ErrorType.Info,
        id: [],
        //@ts-ignore
        polygons,
      }));
      result = false;
    }
  })

  let result = true;

  if (blockBError) {
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg: `${blockFName} 안에 코어 공유세대 블록 (B)이 없습니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: [],
      components: [...cores, ...houses],
    }));

    return false;
  }

  // B블록 안에 코어 블록이 하나만 존재하는지 확인

  let coreHouseError = false;
  buildings.forEach(building => {
    building.parts.forEach(coreHouseCombi => {
      const coreBlocks = coreHouseCombi.parts.filter((block: BuildingPart) => block.blockType === "코어");
      if (coreBlocks.length > 1) {
        errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg: `${coreHouseCombi.name}은(는) 1개의 코어 블록 (C) 블록만을 허용합니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: coreBlocks.map(block => block.uuid),
          components: coreBlocks,
        }));
        coreHouseError = true;
      }
    });
  });

  if (coreHouseError) return false;


  return result;
}



function checkFrontBlockName(blockNameGroup: Map<string, any>, fullName: string, errorList: ErrorList, alphabet: "C" | "U" | "F" | "B") { 
  // '_' 이전의 블록 이름 체크
  const endWithNumExp = new RegExp(`^[${alphabet}]\\d+$`, 'i');
  const alphabetExp = new RegExp(`^[${alphabet}]$`, 'i');
  const correctName = fullName.replace(/\'/gi, "").replace(/\s/gi, "");
  const frontName = alphabet === "C" || alphabet === "U" ? correctName.split('_')[0] : correctName;
  let isNameRight = true;

  if (alphabetExp.test(frontName) && blockNameGroup.get(frontName).length > 1) { // F뒤에 숫자없는데 같은 이름 블록이 여러 개 존재
    errorList.addError({
      type: ErrorType.Error,
      title: "[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.",
      msg: `${fullName}의 알파벳 뒤의 숫자가 누락되었습니다.`,
      components: blockNameGroup.get(fullName),
      id: blockNameGroup.get(fullName).map((block: any) => block.uuid),
    });
    isNameRight = false;
  }
  else if (!alphabetExp.test(frontName) && !endWithNumExp.test(frontName)) { // F숫자가 아님
    errorList.addError({
      type: ErrorType.Error,
      title: "[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.",
      msg: `${fullName} 은(는) 유효하지 않은 블록입니다. 수정이 필요한 블록: ${fullName}`,
      components: blockNameGroup.get(fullName),
      id: blockNameGroup.get(fullName).map((block: any) => block.uuid),
    });
    isNameRight = false;
  }

  if (isNameRight) {
    checkBackBlockName(blockNameGroup, fullName, correctName, errorList, alphabet);
  }
}

function checkBackBlockName(blockNameGroup: Map<string, any>, fullName: string, correctName: string, errorList: ErrorList, alphabet: "F" | "B" | "C" | "U") {
  // '_' 이후의 블록 이름 체크

  const splitedName = correctName.split('_');
  const backName = splitedName.length > 1 ? splitedName.slice(1,).join("_") : "";
  let isNameRight = true;

  if (alphabet === "C" || alphabet === "U") {
    if (backName === "") {
      errorList.addError({
        type: ErrorType.Info,
        title: "[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.",
        msg: `${fullName} 의 면적이 누락 되어 계산 면적으로 입력되었습니다.`,
        components: blockNameGroup.get(fullName),
        id: blockNameGroup.get(fullName).map((block: any) => block.uuid),
      });
      isNameRight = false;
    }
    else if (Number(backName) === 0) {
      errorList.addError({
        type: ErrorType.Warning,
        title: "[면적 오류] 저장 시 입력 면적으로 반영 됩니다.",
        msg: `${fullName} 의 면적 값이 '0' 입니다.`,
        components: blockNameGroup.get(fullName),
        id: blockNameGroup.get(fullName).map((block: any) => block.uuid),
      });
      isNameRight = false;
    }
    else if (alphabet === "C" && isNaN(Number(backName))) {
      errorList.addError({
        type: ErrorType.Error,
        title: "[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.",
        msg: `유효하지 않은 문자가 포함되어 있습니다.
          수정이 필요한 블록: ${fullName}`,
        components: blockNameGroup.get(fullName),
        id: blockNameGroup.get(fullName).map((block: any) => block.uuid),
      });
      isNameRight = false;

    }
    else if (alphabet === "U" && !/^[+-]?\d+(\.\d+)?_[+-]?\d+(\.\d+)?$/i.test(backName) && !/^[+-]?\d+(\.\d+)?_[+-]?\d+(\.\d+)?_[+-]?\d+(\.\d+)?$/i.test(backName) && !Number(backName)) {
      // error
      errorList.addError({
        type: ErrorType.Error,
        title: "[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.",
        msg: `유효하지 않은 문자가 포함되어 있습니다.
          수정이 필요한 블록: ${fullName}`,
        components: blockNameGroup.get(fullName),
        id: blockNameGroup.get(fullName).map((block: any) => block.uuid),
      });
      isNameRight = false;
    }
  }

  if (isNameRight && fullName !== correctName) {
    // 보정 ifno
    errorList.addError({
      type: ErrorType.Info,
      title: "[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.",
      msg: `${fullName}에 유효하지 않은 기호가 포함되어 자동보정 되었습니다.`,
      components: blockNameGroup.get(fullName),
      id: blockNameGroup.get(fullName).map((block: any) => {
        block.name = correctName;
        return block.uuid;
      }),
    });
  }
}

/**
 * 
 * @param buildingsParam 
 * @param notComponentBlock 
 * @param errorList: Logs에 나오는 로그 담는곳 
 * @param type 
 */
export function checkBlockName(buildings: ConverterBuilding[], notComponentBlock: Array<any>, errorList: ErrorList) {

  const blockNameGroup = new Map();
  buildings.forEach((buildingBlock: ConverterBuilding) => getName(buildingBlock));

  function getName(block: ConverterBuilding | BuildingPart | BuildingHouseUnit | BuildingCoreUnit) {
    const name = block.name;
    const nameGroup = blockNameGroup.get(name);
    if (nameGroup) {
      const value = nameGroup.concat([block]);
      blockNameGroup.set(name, value);
    }
    else {
      blockNameGroup.set(name, [block]);
    }
    block.parts && block.parts.forEach(part => getName(part));
  }

  buildings.forEach(building => {
    blockNameGroup.get(building.name)
  });
  
  const buildingBlockName = new Set();
  const partBlockName = new Set();
  const coreBlockName = new Set();
  const houseBlockName = new Set();

  buildings.forEach(building => {
    buildingBlockName.add(building.name);
    building.parts.forEach(part => {
      partBlockName.add(part.name);
      part.parts.forEach(block => {
        if (block.blockType === BuildingBlockType.core) {
          coreBlockName.add(block.name);
        }
        else {
          houseBlockName.add(block.name);
        }
      })
    })
  });

  notComponentBlock.forEach(block => {
    houseBlockName.add(block.name);
  });


  [[buildingBlockName, "F"], [partBlockName, "B"], [coreBlockName, "C"], [houseBlockName, "U"]].forEach((data: Array<any>) => {
    const [block, alphabet] = data;
    //@ts-ignore
    Array.from(block).forEach((name: string) => {
      checkFrontBlockName(blockNameGroup, name, errorList, alphabet);
    })
  })
}
